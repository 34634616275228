export enum SizeVariant {
    small = 13,
    medium = 26
}
export const PortfolioIcon = ({ color, variant = SizeVariant.medium }: { color: string; variant?: SizeVariant }) => {
    return (
        <svg width={variant} height={variant}>
            <rect width="26" height="26" rx="2" ry="2" fill={color} />
        </svg>
    );
};
